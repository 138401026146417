import React from 'react'
import '../css/StatistiquesAujourdhui.css'
import Label from '../svg/Label'
import MeanSalary from '../svg/MeanSalary'
import NbrCommand from '../svg/NbrCommand'
import Shopping from '../svg/Shopping'
import TotalPanier from '../svg/TotalPanier'

function StatistiquesAujourdhui() {
    return (
        <div className='statistiques-aujourdhui'>
            <h1>
                <Label />
                <span>Statistiques d'Aujourd'hui :</span>
            </h1>
            <div className='container-fluid'>
                <div className='row justify-center margin-top'>
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-3 px-2'>
                        <div className='box' style={{background: "linear-gradient(135deg,#FF7A49,#F89C4E)"}}>
                            <NbrCommand />
                            <div className='info'>
                                <h3>Nombre de Commandes</h3>
                                <h2>45 <span>DA</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-3 px-2'>
                        <div className='box' style={{background: "linear-gradient(135deg,#A340B8,#B53F90)"}}>
                            <MeanSalary />
                            <div className='info'>
                                <h3>Salaire Moyen</h3>
                                <h2>45 <span>DA</span> </h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-3 px-2'>
                        <div className='box' style={{background: "linear-gradient(135deg,#4F4DA2,#378AC0)"}}>
                            <Shopping />
                            <div className='info'>
                                <h3>Montant Moyen de Panier</h3>
                                <h2>45 <span>DA</span> </h2>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12 pb-3 px-2'>
                        <div className='box' style={{background: "linear-gradient(135deg,#45d8ae,#55e7c0)"}}>
                            <TotalPanier />
                            <div className='info'>
                                <h3>Total Panier</h3>
                                <h2>45 <span>DA</span></h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default StatistiquesAujourdhui