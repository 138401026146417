import { createContext } from "react";

const UserContext = createContext({
    user: {},
    setUser: () => {},
    itemActive: 'acceuil',
    setItemActive:()=>{},
    filterParams: {id: "", nom: "", dossierBanque: "", dossierClient: "", etat: "", ccr: "", actionBanque: "", searchBox: false },
    setFilterParams:()=>{},
})
export default UserContext