import React, { useContext, useEffect, useState } from 'react'
import '../css/ConsultPage.css'
import axios from 'axios';
import UserContext from '../context/UserContext';
import LoadingComponent from './LoadingComponent';

function DownloadPage({ downloadPageActive, setDownloadPageActive, consultGroupList, refreshHandle }) {
    const { user, setUser, itemActive, setItemActive, filterParams, setFilterParams } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const [files, setFiles] = useState([]);

    useEffect(()=>{
       getAllFiles();
    },[downloadPageActive])

    const logoutFunction = () => {
        setUser(null)
        setItemActive('')
        setFilterParams('')
        localStorage.removeItem('userInfo');
        localStorage.removeItem('itemActive');
        localStorage.removeItem('filterParams');
    }

    const getAllFiles = async()=>{
        setLoading(true)
        await axios.post(
            'https://diardzair.com.dz/api/taksit/rest/set/etatAllFile/',
            JSON.stringify({username: user.user, orderId: user.orderId, list:consultGroupList,}),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
            ).then(res=>{
                if (res.data.errorCode === 7) {
                    logoutFunction();
                }
                setFiles(res.data.files);
                setLoading(false);
                refreshHandle();
            }).catch(err=>{
                console.log(err);
                setLoading(false);
            })
    }


    var list_of_files = '';
    if(loading){
       list_of_files =  <LoadingComponent />
    }else{
       list_of_files = <ul>
        {
            files.map((row1,idx1)=>(
                <React.Fragment key={idx1}>
                    <li className='li-title'>Les fichiers de la demande numéro : {consultGroupList[idx1]}</li>
                    {
                        row1.length === 0 ? <li>Aucun fichier</li>
                        : row1.map((row2)=>(
                            <li key={row2.id}>
                                <span>{row2.nom}  :</span>
                                <span>
                                    <a href={`https://www.diardzair.com.dz/df/${row2.hash}.${row2.url}/`}>Telecharger Ici</a>
                                </span>
                            </li>
                        ))
                    }
                    <hr/>
                </React.Fragment>
            ))
        }
       </ul>
    }

    return (
        <div style={{ display: downloadPageActive == true ? 'block' : 'none' }} className='consult-group'>
            <div className='wrapper' onClick={() => setDownloadPageActive(false)}></div>
            <div className='validate-box'>
                <div className='validate-title'>
                    <span>Téléchargement Groupée</span>
                </div>
                <div className='validate-form'>
                    {list_of_files}
                </div>
            </div>

        </div>
    )
}

export default DownloadPage