import React from 'react'

function Search() {
    return (
        <svg width="37" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50.78 45.36">
            <defs>
                <style>
                    {`
                        .cls-c1 {
                        fill: #e0f6f6;
                        }

                        .cls-c2,
                        .cls-c3 {
                        fill: none;
                        stroke: #1db9aa;
                        stroke-linecap: round;
                        stroke-linejoin: round;
                        }

                        .cls-c2 {
                        stroke-width: 2px;
                        }

                        .cls-c3 {
                        stroke-width: 2px;
                        }
                    `}
                </style>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <polygon className="cls-c1"
                        points="50.78 10.16 50.78 35.2 40.2 45.35 10.38 45.35 0 35.39 0 9.97 10.38 0 40.2 0 50.78 10.16" />
                    <path className="cls-c2"
                        d="M28,27l3.58-3.59h0l9,9a2.54,2.54,0,0,1,0,3.59h0a2.54,2.54,0,0,1-3.59,0h0l-9-9h0Z" />
                    <circle className="cls-c3" cx="21.69" cy="17.12" r="8.45" />
                </g>
            </g>
        </svg>
    )
}

export default Search