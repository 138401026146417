import React from 'react'

function TacheComplementInformationBanque({color}) {
    return (
        color === 'no-color' ? (
            <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                <defs>
                    <style>
                        {`
                            .cls-1 {
                                fill: #535353;
                                }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Слой_1" data-name="Слой 1">
                        <path className="cls-1"
                            d="M.55,35A.55.55,0,0,1,0,34.44a.59.59,0,0,1,0-.14l2.24-8.21A17.48,17.48,0,1,1,9,32.79L.69,35Zm8.52-3.34a.5.5,0,0,1,.27.07,16.41,16.41,0,1,0-6-6,.56.56,0,0,1,0,.42l-2,7.52,7.61-2Z" />
                        <path className="cls-1"
                            d="M17.5,12.57A3.56,3.56,0,1,1,21.05,9,3.56,3.56,0,0,1,17.5,12.57Zm0-6A2.47,2.47,0,1,0,20,9,2.46,2.46,0,0,0,17.5,6.55Z" />
                        <path className="cls-1"
                            d="M17.5,29.52A3.55,3.55,0,0,1,13.94,26V18.58a3.56,3.56,0,0,1,7.11,0V26A3.55,3.55,0,0,1,17.5,29.52Zm0-13.4A2.46,2.46,0,0,0,15,18.58V26A2.46,2.46,0,0,0,20,26V18.58A2.46,2.46,0,0,0,17.5,16.12Z" />
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                <defs>
                    <style>
                        {`
                            .cls-b {
                                fill: #fff;
                                }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Слой_1" data-name="Слой 1">
                        <path className="cls-b"
                            d="M.55,35A.55.55,0,0,1,0,34.44a.59.59,0,0,1,0-.14l2.24-8.21A17.48,17.48,0,1,1,9,32.79L.69,35Zm8.52-3.34a.5.5,0,0,1,.27.07,16.41,16.41,0,1,0-6-6,.56.56,0,0,1,0,.42l-2,7.52,7.61-2Z" />
                        <path className="cls-b"
                            d="M17.5,12.57A3.56,3.56,0,1,1,21.05,9,3.56,3.56,0,0,1,17.5,12.57Zm0-6A2.47,2.47,0,1,0,20,9,2.46,2.46,0,0,0,17.5,6.55Z" />
                        <path className="cls-b"
                            d="M17.5,29.52A3.55,3.55,0,0,1,13.94,26V18.58a3.56,3.56,0,0,1,7.11,0V26A3.55,3.55,0,0,1,17.5,29.52Zm0-13.4A2.46,2.46,0,0,0,15,18.58V26A2.46,2.46,0,0,0,20,26V18.58A2.46,2.46,0,0,0,17.5,16.12Z" />
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheComplementInformationBanque