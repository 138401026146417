import React from 'react'

function Left() {
    return (
        <svg width={'12px'} height={'21px'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.74 21.04"><defs><style>{`.cls-1{fill:#67988e;fill-rule:evenodd;}`}</style></defs><g id="Layer_2" data-name="Layer 2"><g id="Capa_1" data-name="Capa 1"><g id="Rounded_Rectangle_33_copy_4" data-name="Rounded Rectangle 33 copy 4"><path className="cls-1" d="M3.81,10.52l8.46-7.93a1.46,1.46,0,0,0,0-2.15A1.7,1.7,0,0,0,10,.44L.47,9.37A1.47,1.47,0,0,0,0,10.52a1.47,1.47,0,0,0,.47,1.15L10,20.6a1.7,1.7,0,0,0,2.29,0,1.45,1.45,0,0,0,0-2.14Z"/></g></g></g></svg>


    )
}

export default Left