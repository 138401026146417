import React,{useState} from 'react';
import axios from 'axios';
import '../css/DetailModal.css'
import SpanEtat from './etat_components/SpanEtat';

const DetailModal = ({getCommandChild, user,hash,etatChild,setEtatChild,displayModalChild,setDisplayModalChild,actionBanque}) => {
    const [motifRefus, setMotifRefus] = useState("");
    const [piece, setPiece] = useState({});
    
    const validateSubmit = async () => {
        // setLoading(false);
        if(etatChild == ""){
            return;
        }

        if(etatChild == "10") {
            const formData = new FormData();
            formData.append('file', piece.image);
            formData.append('username', user.user);
            formData.append('orderId', user.orderId);
            formData.append('hash', hash);
            formData.append('etat', etatChild);
            await axios
                .post(`https://diardzair.com.dz/api/taksit/rest/up/uploadFiles/`,
                    formData,
                    {
                        headers: {
                             "Content-Type": "multipart/form-data",
                        },
                    })
                .then((res) => {
                    setDisplayModalChild(false)
                    getCommandChild()
                    // setLoading(true);
                })
                .catch((err) => {
                    console.log(err);
                    // setLoading(true);
                });
        }else{
            await axios
                .post(`https://diardzair.com.dz/api/taksit/rest/set/etatDossierBanque`,
                    JSON.stringify({ username: user.user, orderId: user.orderId, hash: hash, etat: etatChild, motifRefus: motifRefus }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded',
                        },
                    })
                .then((res) => {
                    setDisplayModalChild(false)
                    getCommandChild()
                    // setLoading(true);
                })
                .catch((err) => {
                    console.log(err);
                    // setLoading(true);
                });
        }
    }

    const handleImage = (e)=>{
        setPiece({image : e.target.files[0]});
    }


    return (

        <div className={displayModalChild ? 'validate-modal display-modal' : 'validate-modal'}>
            <div className='validate-back' onClick={() => setDisplayModalChild(false)}></div>
            <div className='validate-box'>
                <div className='validate-title'>
                    <span>Validation</span>
                </div>
                <div className='validate-form'>
                    <div className='row'>
                        <div className='col-lg-3'>
                            <label htmlFor="etat">Etat</label>
                        </div>
                        <div className='col-lg-9'>
                            <SpanEtat etat={etatChild} type={'selectDetailModal'} etatFunction={setEtatChild} actionBanque={actionBanque}/>
                        </div>
                        {/* {
                            error !== '' ? (
                                <div className='col-lg-8 offset-lg-4'>
                                    <span className='error'>{error}</span>
                                </div>
                            ): ('')
                        } */}
                    </div>
                    <div className='row'>
                        {
                            etatChild == "10" ? (
                                <>
                                    <div className='col-lg-3'>
                                        <label htmlFor="piece">Piéce jointe</label>
                                    </div>
                                    <div className='col-lg-9'>
                                        <input type='file' id="piece" name="piece" className="form-control"  onChange={handleImage} />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='col-lg-3'>
                                        <label htmlFor="Commentaire">Commentaire</label>
                                    </div>
                                    <div className='col-lg-9'>
                                        <textarea id="Commentaire" name="Commentaire" className="form-control" placeholder='Commentaire'
                                            value={motifRefus} onChange={e => setMotifRefus(e.target.value)} rows="3" />
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <div className='row'>
                        <div className='validate-submit'>
                            <span onClick={validateSubmit}>
                                Valider
                                <svg width="34px" heigh="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 61.06 40.25">
                                    <defs><style>{`.cls-modal-0{fill:#fff;}`}</style></defs>
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <g id="Layer_2-2" data-name="Layer 2">
                                                <g id="Layer_1-2-2" data-name="Layer 1-2">
                                                    <g id="Layer_2-2-2" data-name="Layer 2-2">
                                                        <g id="Layer_1-2-2-2" data-name="Layer 1-2-2"><path className="cls-modal-0" d="M61,5.42a5.47,5.47,0,0,1-2.25,4.44L16.55,40.21a.13.13,0,0,1-.18,0h0l-.21-.32h0L1,18.11a5.49,5.49,0,0,1,1.28-7.57,5.27,5.27,0,0,1,7.36,1.19l.07.11L19,25.2,52.62,1A5.27,5.27,0,0,1,60,2.2a.86.86,0,0,1,.07.1,5.37,5.37,0,0,1,1,3.12Z" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DetailModal