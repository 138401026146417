
import React, { useContext, useState } from 'react'
import axios from 'axios';
import UserContext from '../context/UserContext';

function VersBanqueModal({hash , idModal, check, setCheck}) {
    const { user, setUser } = useContext(UserContext);

    const onCheckVersBanque = async () => {
        await axios.post(`https://diardzair.com.dz/api/taksit/rest/set/set23Banque`,
             JSON.stringify({ username: user.user, orderId:  user.orderId, hash: hash }),
             {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'Content-Type': 'application/json'
                },
            })
            .then(res => {
                console.log('Successs');
                console.log(res)
                setCheck(!check);
            }).catch(err => {
                console.log(err)
            })
    }

  return (
    <div className="modal fade" id={`${idModal}`} tabIndex="-1" aria-labelledby={`${idModal}Label`}
        aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={`${idModal}Label`}>
                        Message de Confirmation 
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={() => onCheckVersBanque()}>Confirmer</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default VersBanqueModal
