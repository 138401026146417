import React, { useState } from 'react'
import { Bar, Doughnut, PolarArea, } from 'react-chartjs-2';
import Label from '../../svg/Label'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale
);

function NbrCommandTypeCompte() {
    const [nbrCommandTypeCompteDate, setNbrCommandTypeCompteDate] = useState("aujourdhui")
    const [nbrCommandTypeCompteOrder, setNbrCommandTypeCompteOrder] = useState("typecompte")

    const setFilterDate = (filter_type) => {
        setNbrCommandTypeCompteDate(filter_type)
    }
    const setFilterOrder = (filter_type) => {
        setNbrCommandTypeCompteOrder(filter_type)
    }

    return (
        <div className='box'>
            <h2>
                <Label widthSize={"20px"} />
                <span>Nombre de Commandes par Type de Compte :</span>
            </h2>
            <div className='statistique-content full-width'>
                <Bar
                    width={600}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                    data={{
                        labels: ['CCP', 'Banque'],
                        datasets: [
                            {
                                label: 'Nombre de Commandes par Type de Compte',
                                data: [30, 40],
                                backgroundColor: ['rgba(75, 192, 192, 1)'],
                                hoverOffset: 4
                            },
                        ],
                    }}
                />
            </div>
            <div className='statistique-content half-width'>
                <Doughnut
                    width={600}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                    data={{
                        labels: ['CCP', 'Banque'],
                        datasets: [
                            {
                                label: 'Nombre de Commandes par Type de Compte',
                                data: [30, 40],
                                backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 205, 86, 1)'],
                                hoverOffset: 4
                            },
                        ],
                    }}
                />
            </div>
            <div className='statistique-content half-width'>
                <PolarArea
                    width={600}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                    data={{
                        labels: ['CCP', 'Banque'],
                        datasets: [
                            {
                                label: 'Nombre de Commandes par Type de Compte',
                                data: [30, 40],
                                backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 205, 86, 1)'],
                                hoverOffset: 4
                            },
                        ],
                    }}
                />
            </div>
            <div className='filter'>
                <div>
                    <ul>
                        <li className={nbrCommandTypeCompteDate === "aujourdhui" ? "filter-active" : ""} onClick={() => setFilterDate('aujourdhui')}>Aujourd'hui</li>
                        <li className={nbrCommandTypeCompteDate === "mois" ? "filter-active" : ""} onClick={() => setFilterDate('mois')}>Mois_En_Cours</li>
                        <li className={nbrCommandTypeCompteDate === "annee" ? "filter-active" : ""} onClick={() => setFilterDate('annee')}>Année_En_Cours</li>
                        <li className={nbrCommandTypeCompteDate === "intervalle" ? "filter-active" : ""} onClick={() => setFilterDate('intervalle')}>
                            Intervalle
                            <div className='interval-pop-up'>
                                <div className='row'>
                                    <div className="form-group col-lg-6">
                                        <label>Date Debut</label>
                                        <input type="date" className="form-control" placeholder="Date Debut" required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label>Date Fin</label>
                                        <input type="date" className="form-control" placeholder="Date Fin" required />
                                    </div>
                                    <div className='text-center'>
                                        <span onClick={() => setFilterDate('intervalle')}>Valider</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li className={nbrCommandTypeCompteOrder === "typecompte" ? "filter-active" : ""} onClick={() => setFilterOrder('typecompte')}>Ordonner_Par_Etat</li>
                        <li className={nbrCommandTypeCompteOrder === "nbrcommand" ? "filter-active" : ""} onClick={() => setFilterOrder('nbrcommand')}>Ordonner_Par_Nombre_De_Commandes</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NbrCommandTypeCompte