import React from 'react'

function ArrowBottom({width ="30px" , height = "20px"}) {
    return (
        <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 15.97">
            <g id="Layer_2" data-name="Layer 2">
                <g id="Capa_1" data-name="Capa 1">
                    <path d="M29.7,1.65,28.2.27A1,1,0,0,0,27.51,0a1,1,0,0,0-.7.27L15,11.06,3.19.27A1,1,0,0,0,1.8.27L.3,1.65a.81.81,0,0,0,0,1.26l14,12.79a1,1,0,0,0,1.38,0l14-12.79a.81.81,0,0,0,0-1.26Z" />
                </g>
            </g>
        </svg>

    )
}

export default ArrowBottom