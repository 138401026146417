import React from 'react'

function TacheReceptionDossierBanque({color}) {
    return (
        color === 'no-color' ? (
            <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                <defs>
                    <style>
                        {`
                            .cls-11,
                            .cls-12 {
                            fill: none;
                            stroke: #535353;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            }

                            .cls-11 {
                            stroke-width: 2px;
                            }

                            .cls-12 {
                            stroke-width: 2px;
                            }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="_7._reception" data-name="7. reception">
                            <rect className="cls-11" x="1" y="25.62" width="40" height="3.85" />
                            <rect className="cls-11" x="3.86" y="29.46" width="34.29"
                                height="11.54" />
                            <ellipse className="cls-12" cx="11.36" cy="10.62" rx="2.5" ry="2.69" />
                            <path className="cls-11"
                                d="M3.86,24.08v1.54h15V24.46c0-4.46-3.36-8.08-7.5-8.08S3.86,20,3.86,24.46Z" />
                            <path className="cls-11"
                                d="M6.71,22.54H9.57A1.49,1.49,0,0,1,11,24.08h0a1.49,1.49,0,0,1-1.43,1.54H6.71" />
                            <path className="cls-11"
                                d="M16,22.54H13.14a1.49,1.49,0,0,0-1.43,1.54h0a1.49,1.49,0,0,0,1.43,1.54H16" />
                            <rect className="cls-11" x="28.14" y="17.92" width="10" height="7.69" />
                            <ellipse className="cls-11" cx="33.14" cy="4.85" rx="3.57" ry="3.85" />
                            <line className="cls-11" x1="33.14" y1="3.31" x2="33.14" y2="4.85" />
                            <line className="cls-11" x1="32.43" y1="4.85" x2="33.14" y2="4.85" />
                        </g>
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
                <defs>
                    <style>
                        {`
                            .cls-b1,
                            .cls-b2 {
                            fill: none;
                            stroke: #fff;
                            stroke-linecap: round;
                            stroke-linejoin: round;
                            }

                            .cls-b1 {
                            stroke-width: 2px;
                            }

                            .cls-b2 {
                            stroke-width: 2px;
                            }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="_7._reception" data-name="7. reception">
                            <rect className="cls-b1" x="1" y="25.62" width="40" height="3.85" />
                            <rect className="cls-b1" x="3.86" y="29.46" width="34.29"
                                height="11.54" />
                            <ellipse className="cls-b2" cx="11.36" cy="10.62" rx="2.5" ry="2.69" />
                            <path className="cls-b1"
                                d="M3.86,24.08v1.54h15V24.46c0-4.46-3.36-8.08-7.5-8.08S3.86,20,3.86,24.46Z" />
                            <path className="cls-b1"
                                d="M6.71,22.54H9.57A1.49,1.49,0,0,1,11,24.08h0a1.49,1.49,0,0,1-1.43,1.54H6.71" />
                            <path className="cls-b1"
                                d="M16,22.54H13.14a1.49,1.49,0,0,0-1.43,1.54h0a1.49,1.49,0,0,0,1.43,1.54H16" />
                            <rect className="cls-b1" x="28.14" y="17.92" width="10" height="7.69" />
                            <ellipse className="cls-b1" cx="33.14" cy="4.85" rx="3.57" ry="3.85" />
                            <line className="cls-b1" x1="33.14" y1="3.31" x2="33.14" y2="4.85" />
                            <line className="cls-b1" x1="32.43" y1="4.85" x2="33.14" y2="4.85" />
                        </g>
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheReceptionDossierBanque