import React from 'react'

function TacheConsultationCentralRisque({ color }) {
    return (
        color === 'no-color' ? (
            <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
                <defs>
                    <style>
                        {`
                            .cls-1 {
                                fill: #535353;
                                }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-1"
                            d="M38.77,9.44a2.54,2.54,0,0,0-3.51,0l-.46.46a.88.88,0,0,1-.63.26h-.11a.86.86,0,0,1-.63-.26l-.51-.5a.9.9,0,0,1-.26-.64.88.88,0,0,1,.34-.7l.41-.33a1.83,1.83,0,0,0,.7-1.45A1.91,1.91,0,0,0,33.57,5L32.24,3.63a2.54,2.54,0,0,0-3.51,0l-1,1a3,3,0,0,0-.9,2.17A3.07,3.07,0,0,0,27.1,8a13.58,13.58,0,0,0,7,7.07l1.47.66a3,3,0,0,0,3.38-.63l1-1a2.46,2.46,0,0,0,0-3.5Zm.12,3.62-1,1a1.61,1.61,0,0,1-1.77.33l-1.47-.65a12.13,12.13,0,0,1-6.23-6.32,1.53,1.53,0,0,1-.13-.64,1.58,1.58,0,0,1,.46-1.13l1-1a1,1,0,0,1,1.45,0L32.54,6a.38.38,0,0,1,.12.29.41.41,0,0,1-.15.32l-.42.33a2.34,2.34,0,0,0-.88,1.83,2.3,2.3,0,0,0,.69,1.66l.5.5a2.3,2.3,0,0,0,1.66.69h.11a2.36,2.36,0,0,0,1.66-.68l.46-.47a1,1,0,0,1,1.45,0l1.15,1.15a1,1,0,0,1,.3.73A1,1,0,0,1,38.89,13.06Z" />
                        <path className="cls-1"
                            d="M13.4,36.61l1.72-1,.5.21.52,1.9h4l.52-1.9.5-.21,1.72,1,2.83-2.84-1-1.71a5.32,5.32,0,0,0,.21-.5l1.9-.52V27L25,26.51a4.4,4.4,0,0,0-.21-.5l1-1.72-2.83-2.83-1.72,1a5.32,5.32,0,0,0-.5-.21l-.52-1.91h-4l-.52,1.91a5.32,5.32,0,0,0-.5.21l-1.72-1-2.83,2.83,1,1.72a4.4,4.4,0,0,0-.21.5L9.44,27v4l1.9.52c.06.16.13.33.21.5l-1,1.71Zm-2.51-6.68v-1.8l1.59-.43.12-.38a5.69,5.69,0,0,1,.41-1L13.2,26l-.82-1.44,1.27-1.26,1.43.82.36-.19a6.53,6.53,0,0,1,1-.42l.38-.11.44-1.6H19l.44,1.6.38.11a6.53,6.53,0,0,1,1,.42l.36.18,1.43-.82,1.27,1.27L23.09,26l.19.35a5.69,5.69,0,0,1,.41,1l.12.38,1.59.44v1.79l-1.59.44-.12.38a5.6,5.6,0,0,1-.41,1l-.19.35.82,1.44L22.64,34.8,21.21,34l-.36.19a5.31,5.31,0,0,1-1,.41l-.38.12L19,36.29H17.25l-.44-1.59-.38-.12a5.6,5.6,0,0,1-1-.41L15.08,34l-1.43.82-1.27-1.27.82-1.44L13,31.74a5.6,5.6,0,0,1-.41-1l-.12-.38Z" />
                        <path className="cls-1"
                            d="M18.15,33.39A4.36,4.36,0,1,0,13.79,29,4.36,4.36,0,0,0,18.15,33.39Zm0-7.26A2.91,2.91,0,1,1,15.24,29,2.9,2.9,0,0,1,18.15,26.13Z" />
                        <path className="cls-1"
                            d="M42.82,0H24a2.19,2.19,0,0,0-2.18,2.18V8h-8a2.19,2.19,0,0,0-2.1,1.63A13.71,13.71,0,0,0,4.35,21.77v3.69a5.09,5.09,0,0,0-4.35,5v2.91a5.09,5.09,0,0,0,5.08,5.08H8.71V25.4H5.81V21.77a12.24,12.24,0,0,1,6.06-10.6,2.17,2.17,0,0,0,1.92,1.17h8v4.35A2.19,2.19,0,0,0,24,18.87h6.18a12.31,12.31,0,0,1,.35,2.9V25.4h-2.9V38.47H29a3.64,3.64,0,0,1-3.56,2.9H21.67a2.9,2.9,0,0,0-2.8-2.18H16A2.91,2.91,0,1,0,16,45h2.9a2.9,2.9,0,0,0,2.8-2.18H25.4a5.09,5.09,0,0,0,5-4.35h.78a5.09,5.09,0,0,0,5.08-5.08V30.48a5.09,5.09,0,0,0-4.35-5V21.77a14.07,14.07,0,0,0-.31-2.9h1.76v4.21l6.74-4.21h2.69A2.19,2.19,0,0,0,45,16.69V2.18A2.19,2.19,0,0,0,42.82,0ZM7.26,37H5.81V28.31H4.35v8.63a3.63,3.63,0,0,1-2.9-3.55V30.48a3.64,3.64,0,0,1,3.63-3.63H7.26Zm11.61,6.53H16a1.45,1.45,0,1,1,0-2.9h2.9a1.45,1.45,0,0,1,0,2.9Zm16-13.07v2.91a3.63,3.63,0,0,1-2.9,3.55V28.31H30.48V37H29V26.85h2.18A3.64,3.64,0,0,1,34.84,30.48Zm-17.42-21v1.45H16V9.44Zm-4.36.72a.73.73,0,0,1,.73-.72h.73v1.45h-.73A.73.73,0,0,1,13.06,10.16Zm5.81.73V9.44h2.9v1.45Zm24.68,5.8a.73.73,0,0,1-.73.73H39.71l-4.87,3v-3H24a.73.73,0,0,1-.72-.73V2.18A.73.73,0,0,1,24,1.45H42.82a.73.73,0,0,1,.73.73Z" />
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45">
                <defs>
                    <style>
                        {`
                        .cls-b {
                            fill: #fff;
                            }
                    `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="cls-b"
                            d="M38.77,9.44a2.54,2.54,0,0,0-3.51,0l-.46.46a.88.88,0,0,1-.63.26h-.11a.86.86,0,0,1-.63-.26l-.51-.5a.9.9,0,0,1-.26-.64.88.88,0,0,1,.34-.7l.41-.33a1.83,1.83,0,0,0,.7-1.45A1.91,1.91,0,0,0,33.57,5L32.24,3.63a2.54,2.54,0,0,0-3.51,0l-1,1a3,3,0,0,0-.9,2.17A3.07,3.07,0,0,0,27.1,8a13.58,13.58,0,0,0,7,7.07l1.47.66a3,3,0,0,0,3.38-.63l1-1a2.46,2.46,0,0,0,0-3.5Zm.12,3.62-1,1a1.61,1.61,0,0,1-1.77.33l-1.47-.65a12.13,12.13,0,0,1-6.23-6.32,1.53,1.53,0,0,1-.13-.64,1.58,1.58,0,0,1,.46-1.13l1-1a1,1,0,0,1,1.45,0L32.54,6a.38.38,0,0,1,.12.29.41.41,0,0,1-.15.32l-.42.33a2.34,2.34,0,0,0-.88,1.83,2.3,2.3,0,0,0,.69,1.66l.5.5a2.3,2.3,0,0,0,1.66.69h.11a2.36,2.36,0,0,0,1.66-.68l.46-.47a1,1,0,0,1,1.45,0l1.15,1.15a1,1,0,0,1,.3.73A1,1,0,0,1,38.89,13.06Z" />
                        <path className="cls-b"
                            d="M13.4,36.61l1.72-1,.5.21.52,1.9h4l.52-1.9.5-.21,1.72,1,2.83-2.84-1-1.71a5.32,5.32,0,0,0,.21-.5l1.9-.52V27L25,26.51a4.4,4.4,0,0,0-.21-.5l1-1.72-2.83-2.83-1.72,1a5.32,5.32,0,0,0-.5-.21l-.52-1.91h-4l-.52,1.91a5.32,5.32,0,0,0-.5.21l-1.72-1-2.83,2.83,1,1.72a4.4,4.4,0,0,0-.21.5L9.44,27v4l1.9.52c.06.16.13.33.21.5l-1,1.71Zm-2.51-6.68v-1.8l1.59-.43.12-.38a5.69,5.69,0,0,1,.41-1L13.2,26l-.82-1.44,1.27-1.26,1.43.82.36-.19a6.53,6.53,0,0,1,1-.42l.38-.11.44-1.6H19l.44,1.6.38.11a6.53,6.53,0,0,1,1,.42l.36.18,1.43-.82,1.27,1.27L23.09,26l.19.35a5.69,5.69,0,0,1,.41,1l.12.38,1.59.44v1.79l-1.59.44-.12.38a5.6,5.6,0,0,1-.41,1l-.19.35.82,1.44L22.64,34.8,21.21,34l-.36.19a5.31,5.31,0,0,1-1,.41l-.38.12L19,36.29H17.25l-.44-1.59-.38-.12a5.6,5.6,0,0,1-1-.41L15.08,34l-1.43.82-1.27-1.27.82-1.44L13,31.74a5.6,5.6,0,0,1-.41-1l-.12-.38Z" />
                        <path className="cls-b"
                            d="M18.15,33.39A4.36,4.36,0,1,0,13.79,29,4.36,4.36,0,0,0,18.15,33.39Zm0-7.26A2.91,2.91,0,1,1,15.24,29,2.9,2.9,0,0,1,18.15,26.13Z" />
                        <path className="cls-b"
                            d="M42.82,0H24a2.19,2.19,0,0,0-2.18,2.18V8h-8a2.19,2.19,0,0,0-2.1,1.63A13.71,13.71,0,0,0,4.35,21.77v3.69a5.09,5.09,0,0,0-4.35,5v2.91a5.09,5.09,0,0,0,5.08,5.08H8.71V25.4H5.81V21.77a12.24,12.24,0,0,1,6.06-10.6,2.17,2.17,0,0,0,1.92,1.17h8v4.35A2.19,2.19,0,0,0,24,18.87h6.18a12.31,12.31,0,0,1,.35,2.9V25.4h-2.9V38.47H29a3.64,3.64,0,0,1-3.56,2.9H21.67a2.9,2.9,0,0,0-2.8-2.18H16A2.91,2.91,0,1,0,16,45h2.9a2.9,2.9,0,0,0,2.8-2.18H25.4a5.09,5.09,0,0,0,5-4.35h.78a5.09,5.09,0,0,0,5.08-5.08V30.48a5.09,5.09,0,0,0-4.35-5V21.77a14.07,14.07,0,0,0-.31-2.9h1.76v4.21l6.74-4.21h2.69A2.19,2.19,0,0,0,45,16.69V2.18A2.19,2.19,0,0,0,42.82,0ZM7.26,37H5.81V28.31H4.35v8.63a3.63,3.63,0,0,1-2.9-3.55V30.48a3.64,3.64,0,0,1,3.63-3.63H7.26Zm11.61,6.53H16a1.45,1.45,0,1,1,0-2.9h2.9a1.45,1.45,0,0,1,0,2.9Zm16-13.07v2.91a3.63,3.63,0,0,1-2.9,3.55V28.31H30.48V37H29V26.85h2.18A3.64,3.64,0,0,1,34.84,30.48Zm-17.42-21v1.45H16V9.44Zm-4.36.72a.73.73,0,0,1,.73-.72h.73v1.45h-.73A.73.73,0,0,1,13.06,10.16Zm5.81.73V9.44h2.9v1.45Zm24.68,5.8a.73.73,0,0,1-.73.73H39.71l-4.87,3v-3H24a.73.73,0,0,1-.72-.73V2.18A.73.73,0,0,1,24,1.45H42.82a.73.73,0,0,1,.73.73Z" />
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheConsultationCentralRisque