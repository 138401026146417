import React from 'react'

function TacheNotificationBanque({color}) {
    return (
        color === 'no-color' ? (
            <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                <defs>
                    <style>
                        {`
                        .cls-1 {
                            fill: #535353;
                            }
                   `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Layer_2-2" data-name="Layer 2">
                            <path className="cls-1"
                                d="M19,38a6.1,6.1,0,0,1-6.33-5.85,1.53,1.53,0,0,1,1.58-1.46h9.5a1.53,1.53,0,0,1,1.58,1.46A6.1,6.1,0,0,1,19,38Zm-2.74-4.38a3.33,3.33,0,0,0,4.33,1.06,3.09,3.09,0,0,0,1.15-1.06Z" />
                            <path className="cls-1"
                                d="M22.17,5.85H15.83a1.53,1.53,0,0,1-1.58-1.47A4.58,4.58,0,0,1,19,0a4.58,4.58,0,0,1,4.75,4.38A1.53,1.53,0,0,1,22.17,5.85Z" />
                            <path className="cls-1"
                                d="M36.42,33.62H1.58A1.53,1.53,0,0,1,0,32.15a10,10,0,0,1,4.75-8.4V16.08C4.75,8.85,11.07,3,18.9,2.92h.18c7.87,0,14.25,5.89,14.25,13.16v7.67A10,10,0,0,1,38,32.15h0A1.53,1.53,0,0,1,36.42,33.62ZM3.32,30.69H34.67a7.31,7.31,0,0,0-3.8-4.86,1.47,1.47,0,0,1-.79-1.28V16.08c0-5.65-5-10.23-11.08-10.23S7.92,10.43,7.92,16.08v8.47a1.46,1.46,0,0,1-.8,1.28A7.37,7.37,0,0,0,3.32,30.69Z" />
                        </g>
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                <defs>
                    <style>
                        {`
                        .cls-b {
                            fill: #fff;
                            }
                   `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Layer_2-2" data-name="Layer 2">
                            <path className="cls-b"
                                d="M19,38a6.1,6.1,0,0,1-6.33-5.85,1.53,1.53,0,0,1,1.58-1.46h9.5a1.53,1.53,0,0,1,1.58,1.46A6.1,6.1,0,0,1,19,38Zm-2.74-4.38a3.33,3.33,0,0,0,4.33,1.06,3.09,3.09,0,0,0,1.15-1.06Z" />
                            <path className="cls-b"
                                d="M22.17,5.85H15.83a1.53,1.53,0,0,1-1.58-1.47A4.58,4.58,0,0,1,19,0a4.58,4.58,0,0,1,4.75,4.38A1.53,1.53,0,0,1,22.17,5.85Z" />
                            <path className="cls-b"
                                d="M36.42,33.62H1.58A1.53,1.53,0,0,1,0,32.15a10,10,0,0,1,4.75-8.4V16.08C4.75,8.85,11.07,3,18.9,2.92h.18c7.87,0,14.25,5.89,14.25,13.16v7.67A10,10,0,0,1,38,32.15h0A1.53,1.53,0,0,1,36.42,33.62ZM3.32,30.69H34.67a7.31,7.31,0,0,0-3.8-4.86,1.47,1.47,0,0,1-.79-1.28V16.08c0-5.65-5-10.23-11.08-10.23S7.92,10.43,7.92,16.08v8.47a1.46,1.46,0,0,1-.8,1.28A7.37,7.37,0,0,0,3.32,30.69Z" />
                        </g>
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheNotificationBanque