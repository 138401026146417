import React,{useState} from 'react'
import '../../css/TheadFilter.css'
import moment from 'moment'

function TheadFilter({ type, fieldActive, setFieldAttribute, setOrderByAttribute }) {
    const [theadCalendarPopup, setTheadCalendarPopup] = useState(false);
    const [dateSaisie, setDateSaisie] = useState(moment().format('YYYYY-MM-DD'));

    const fillOrderByFieldAttribute = (arg1, arg2) => {
        setOrderByAttribute(arg1);
        setFieldAttribute(arg2);
    }

    const clickOkHandler =()=>{
        const date = moment(dateSaisie).format('YYYY-MM-DD')
        fillOrderByFieldAttribute(date,type);
        setTheadCalendarPopup(false)
    }

    return (
        <>
            <>
                {
                    type === 'MTProduitField' ? (
                        <ul style={{ display: fieldActive === true ? 'block' : 'none' }}>
                            <li onClick={() => fillOrderByFieldAttribute('', '')}>Tous</li>
                            <li onClick={() => fillOrderByFieldAttribute('asc', 'mtProduitField')}>Asc</li>
                            <li onClick={() => fillOrderByFieldAttribute('desc', 'mtProduitField')}>Desc</li>
                        </ul>
                    ) : type === 'TypeField' ? (
                        <ul style={{ display: fieldActive === true ? 'block' : 'none' }}>
                            <li onClick={() => fillOrderByFieldAttribute('', '')}>Tous</li>
                            <li onClick={() => fillOrderByFieldAttribute('standard', 'typeField')}>Standard</li>
                        </ul>
                    ) : type === 'VehiculeField' ? (
                        <ul style={{ display: fieldActive === true ? 'block' : 'none' }}>
                            <li onClick={() => fillOrderByFieldAttribute('', '')}>Tous</li>
                            <li onClick={() => fillOrderByFieldAttribute('desc', 'vehiculeField')}>Vehicule</li>
                            <li onClick={() => fillOrderByFieldAttribute('asc', 'vehiculeField')}>Electro</li>
                        </ul>
                    ) : type === 'dateActionBanqueField' ? (
                        <ul className='translate-30' style={{ display: fieldActive === true ? 'block' : 'none' }}>
                            <li onClick={() => fillOrderByFieldAttribute('', '')}>Tous</li>
                            <li onClick={() => fillOrderByFieldAttribute('asc', 'dateActionBanqueField')}>Asc</li>
                            <li onClick={() => fillOrderByFieldAttribute('desc', 'dateActionBanqueField')}>Desc</li>
                            <li onClick={()=>setTheadCalendarPopup(true)}>Calendrier</li>
                        </ul>
                    ) : type === 'dateAccuseField' ? (
                        <ul style={{ display: fieldActive === true ? 'block' : 'none' }}>
                            <li onClick={() => fillOrderByFieldAttribute('', '')}>Tous</li>
                            <li onClick={() => fillOrderByFieldAttribute('asc', 'dateAccuseField')}>Asc</li>
                            <li onClick={() => fillOrderByFieldAttribute('desc', 'dateAccuseField')}>Desc</li>
                            <li onClick={()=>setTheadCalendarPopup(true)}>Calendrier</li>
                        </ul>
                    ) : ''
                }
            </>
            <div className='thead-calendar-popup' style={{ display : theadCalendarPopup === true ? 'block' : 'none' }}>
                <div className='wrapper' onClick={()=>setTheadCalendarPopup(false)}></div>
                <div className='box'>
                    <h2>Calendrier</h2>
                    <input type='date' value={dateSaisie} onChange={(e)=>setDateSaisie(e.target.value)} className='form-control' />
                    <button onClick={clickOkHandler} type='button' >Ok</button>
                </div>
            </div>
        </>

    )
}

export default TheadFilter