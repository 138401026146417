import React from 'react'

function TacheConfirmationPaiementBanque({color}) {
    return (
        color === 'no-color' ? (
            <svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                <defs>
                    <style>
                        {`
                            .cls-1 {
                                fill: #535353;
                                }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="_21._Verified" data-name=" 21. Verified">
                            <path className="cls-1"
                                d="M36.42,21.77a4.59,4.59,0,0,1,0-5.58l1.26-1.67a1.53,1.53,0,0,0-.36-2.21,2,2,0,0,0-.47-.23l-2-.63a4.77,4.77,0,0,1-3.44-4.51V4.87a1.62,1.62,0,0,0-1.65-1.58,1.7,1.7,0,0,0-.51.08L27.15,4a5.1,5.1,0,0,1-5.54-1.73L20.34.61A1.78,1.78,0,0,0,17.92.36a3.07,3.07,0,0,0-.26.25L16.39,2.28A5.09,5.09,0,0,1,10.85,4L8.78,3.37a1.67,1.67,0,0,0-2.08,1,1.34,1.34,0,0,0-.08.48V6.94a4.77,4.77,0,0,1-3.44,4.51l-2,.63a1.58,1.58,0,0,0-1.07,2,1.45,1.45,0,0,0,.24.44l1.26,1.67a4.59,4.59,0,0,1,0,5.58L.32,23.44a1.53,1.53,0,0,0,.36,2.21,2,2,0,0,0,.47.23l2,.63A4.77,4.77,0,0,1,6.62,31v2.07a1.62,1.62,0,0,0,1.65,1.58,1.82,1.82,0,0,0,.52-.08L10.84,34a5.08,5.08,0,0,1,5.56,1.73l1.26,1.66A1.71,1.71,0,0,0,20,37.7a1.55,1.55,0,0,0,.37-.35l1.26-1.66A5.1,5.1,0,0,1,27.16,34l2.05.63a1.67,1.67,0,0,0,2.09-1,1.31,1.31,0,0,0,.08-.48V31a4.78,4.78,0,0,1,3.44-4.52l2-.63a1.54,1.54,0,0,0,.83-2.44Zm-2.75,1.77a7.94,7.94,0,0,0-5.6,7.36A8.51,8.51,0,0,0,19,33.72a8.38,8.38,0,0,0-6.62-3.17,8.85,8.85,0,0,0-2.45.35,8,8,0,0,0-5.6-7.37,7.61,7.61,0,0,0,0-9.11,8,8,0,0,0,5.6-7.37A8.49,8.49,0,0,0,19,4.24a8.51,8.51,0,0,0,9.07,2.82,8,8,0,0,0,5.6,7.37A7.55,7.55,0,0,0,33.67,23.54Z" />
                            <path className="cls-1"
                                d="M24.46,14.69l-7.12,6.8-3.8-3.63a1.7,1.7,0,0,0-2.34,0,1.53,1.53,0,0,0,0,2.24l0,0,5,4.75a1.7,1.7,0,0,0,2.34,0l8.29-7.92a1.53,1.53,0,0,0,0-2.24A1.72,1.72,0,0,0,24.46,14.69Z" />
                        </g>
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="22" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38">
                <defs>
                    <style>
                        {`
                        .cls-b {
                            fill: #fff;
                            }
                   `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="_21._Verified" data-name=" 21. Verified">
                            <path className="cls-b"
                                d="M36.42,21.77a4.59,4.59,0,0,1,0-5.58l1.26-1.67a1.53,1.53,0,0,0-.36-2.21,2,2,0,0,0-.47-.23l-2-.63a4.77,4.77,0,0,1-3.44-4.51V4.87a1.62,1.62,0,0,0-1.65-1.58,1.7,1.7,0,0,0-.51.08L27.15,4a5.1,5.1,0,0,1-5.54-1.73L20.34.61A1.78,1.78,0,0,0,17.92.36a3.07,3.07,0,0,0-.26.25L16.39,2.28A5.09,5.09,0,0,1,10.85,4L8.78,3.37a1.67,1.67,0,0,0-2.08,1,1.34,1.34,0,0,0-.08.48V6.94a4.77,4.77,0,0,1-3.44,4.51l-2,.63a1.58,1.58,0,0,0-1.07,2,1.45,1.45,0,0,0,.24.44l1.26,1.67a4.59,4.59,0,0,1,0,5.58L.32,23.44a1.53,1.53,0,0,0,.36,2.21,2,2,0,0,0,.47.23l2,.63A4.77,4.77,0,0,1,6.62,31v2.07a1.62,1.62,0,0,0,1.65,1.58,1.82,1.82,0,0,0,.52-.08L10.84,34a5.08,5.08,0,0,1,5.56,1.73l1.26,1.66A1.71,1.71,0,0,0,20,37.7a1.55,1.55,0,0,0,.37-.35l1.26-1.66A5.1,5.1,0,0,1,27.16,34l2.05.63a1.67,1.67,0,0,0,2.09-1,1.31,1.31,0,0,0,.08-.48V31a4.78,4.78,0,0,1,3.44-4.52l2-.63a1.54,1.54,0,0,0,.83-2.44Zm-2.75,1.77a7.94,7.94,0,0,0-5.6,7.36A8.51,8.51,0,0,0,19,33.72a8.38,8.38,0,0,0-6.62-3.17,8.85,8.85,0,0,0-2.45.35,8,8,0,0,0-5.6-7.37,7.61,7.61,0,0,0,0-9.11,8,8,0,0,0,5.6-7.37A8.49,8.49,0,0,0,19,4.24a8.51,8.51,0,0,0,9.07,2.82,8,8,0,0,0,5.6,7.37A7.55,7.55,0,0,0,33.67,23.54Z" />
                            <path className="cls-b"
                                d="M24.46,14.69l-7.12,6.8-3.8-3.63a1.7,1.7,0,0,0-2.34,0,1.53,1.53,0,0,0,0,2.24l0,0,5,4.75a1.7,1.7,0,0,0,2.34,0l8.29-7.92a1.53,1.53,0,0,0,0-2.24A1.72,1.72,0,0,0,24.46,14.69Z" />
                        </g>
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheConfirmationPaiementBanque