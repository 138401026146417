import React, { useContext, useEffect } from 'react'
import StatistiquesAujourdhui from '../components/StatistiquesAujourdhui'
import StatistiquesGlobal from '../components/StatistiquesGlobal'
import UserContext from '../context/UserContext'
import '../css/StatistiquesPage.css'

function StatistiquesPage() {
  const { itemActive, setItemActive } = useContext(UserContext)

  useEffect(() => {
    setItemActive('statistiques')
    localStorage.setItem('itemActive', 'statistiques')
  }, [])
  return (
    <div className='statistiques-page'>
      <StatistiquesAujourdhui />
      <StatistiquesGlobal />
    </div>
  )
}

export default StatistiquesPage