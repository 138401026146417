import React,{ useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import UserContext from '../context/UserContext';

function VisibleLayout() {
    const { user, setUser } = useContext(UserContext);

    // if (user && user.role==='isClient') {
    //     return <Navigate to="/contrat/list" />;
    // }
    // if (user && user.role==='isAdmin') {
    //     return <Navigate to="/achat/list" />;
    // }

    if (user) {
        return <Navigate to="/banque/page/1" />;
    }

    return (
        <div className="auth-section">
            <Outlet />
        </div>
    )
};
export default VisibleLayout