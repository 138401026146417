import React, { useState } from 'react'
import { Bar, Doughnut, PolarArea, } from 'react-chartjs-2';
import Label from '../../svg/Label'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    RadialLinearScale
);
function SalaryEtat() {
    const [salaryEtatDate, setSalaryEtatDate] = useState("aujourdhui")
    const [salaryEtatOrder, setSalaryEtatOrder] = useState("etat")

    const setFilterDate = (filter_type) => {
        setSalaryEtatDate(filter_type)
    }
    const setFilterOrder = (filter_type) => {
        setSalaryEtatOrder(filter_type)
    }
    return (
        <div className='box'>
            <h2>
                <Label widthSize={"20px"} />
                <span>Salaire Moyen par Etat :</span>
            </h2>
            <div className='statistique-content full-width'>
                <Bar
                    width={600}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                    data={{
                        labels: ['Non Traité', 'Accepté', 'Refusé', 'Approuvé', 'Annulé', 'Financable', 'Non Financable', 'Rejeté', 'Controlé'],
                        datasets: [
                            {
                                label: 'Salaire Moyen par Etat',
                                data: [10, 70, 30, 40, 80, 60, 25, 30, 40],
                                backgroundColor: ['rgba(75, 192, 192, 1)'],
                                hoverOffset: 4
                            },
                        ],
                    }}
                />
            </div>
            <div className='statistique-content half-width'>
                <Doughnut
                    width={600}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                    data={{
                        labels: ['Non Traité', 'Accepté', 'Refusé', 'Approuvé', 'Annulé', 'Financable', 'Non Financable', 'Rejeté', 'Controlé'],
                        datasets: [
                            {
                                label: 'Salaire Moyen par Etat',
                                data: [10, 70, 30, 40, 80, 60, 25, 30, 40],
                                backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 205, 86, 1)', 'rgba(162, 128, 137, 1)', 'rgba(132, 88, 179, 1)', 'rgba(229, 234, 245, 1)', 'rgba(222, 166, 175, 1)', 'rgba(62, 111, 135, 1);'],
                                hoverOffset: 4
                            },
                        ],
                    }}
                />
            </div>
            <div className='statistique-content half-width'>
                <PolarArea
                    width={600}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                    data={{
                        labels: ['Non Traité', 'Accepté', 'Refusé', 'Approuvé', 'Annulé', 'Financable', 'Non Financable', 'Rejeté', 'Controlé'],
                        datasets: [
                            {
                                label: 'Salaire Moyen par Etat',
                                data: [10, 70, 30, 40, 80, 60, 25, 30, 40],
                                backgroundColor: ['rgba(75, 192, 192, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 99, 132, 1)', 'rgba(255, 205, 86, 1)', 'rgba(162, 128, 137, 1)', 'rgba(132, 88, 179, 1)', 'rgba(229, 234, 245, 1)', 'rgba(222, 166, 175, 1)', 'rgba(62, 111, 135, 1);'],
                                hoverOffset: 4
                            },
                        ],
                    }}
                />
            </div>
            <div className='filter'>
                <div>
                    <ul>
                        <li className={salaryEtatDate === "aujourdhui" ? "filter-active" : ""} onClick={() => setFilterDate('aujourdhui')}>Aujourd'hui</li>
                        <li className={salaryEtatDate === "mois" ? "filter-active" : ""} onClick={() => setFilterDate('mois')}>Mois_En_Cours</li>
                        <li className={salaryEtatDate === "annee" ? "filter-active" : ""} onClick={() => setFilterDate('annee')}>Année_En_Cours</li>
                        <li className={salaryEtatDate === "intervalle" ? "filter-active" : ""} onClick={() => setFilterDate('intervalle')}>
                            Intervalle
                            <div className='interval-pop-up'>
                                <div className='row'>
                                    <div className="form-group col-lg-6">
                                        <label>Date Debut</label>
                                        <input type="date" className="form-control" placeholder="Date Debut" required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label>Date Fin</label>
                                        <input type="date" className="form-control" placeholder="Date Fin" required />
                                    </div>
                                    <div className='text-center'>
                                        <span onClick={() => setFilterDate('intervalle')}>Valider</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li className={salaryEtatOrder === "etat" ? "filter-active" : ""} onClick={() => setFilterOrder('etat')}>Ordonner_Par_Etat</li>
                        <li className={salaryEtatOrder === "salaire" ? "filter-active" : ""} onClick={() => setFilterOrder('salaire')}>Ordonner_Par_Salaire</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SalaryEtat