import React from 'react'

function TacheTout({ color }) {
    return (
        color === 'no-color' ? (
            <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
                <defs>
                    <style>{`.cls-detail-0{fill:none;stroke:#535353;stroke-miterlimit:10;stroke-width:3px;}`}</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Layer_2-2" data-name="Layer 2">
                            <g id="Layer_1-2-2" data-name="Layer 1-2">
                                <path className="cls-detail-0" d="M6.69,1.5H9.31A5.24,5.24,0,0,1,14.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H6.69A5.24,5.24,0,0,1,1.5,9.46V6.79A5.24,5.24,0,0,1,6.69,1.5Z" />
                                <path className="cls-detail-0" d="M23.69,1.5h2.62A5.24,5.24,0,0,1,31.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H23.69A5.24,5.24,0,0,1,18.5,9.46V6.79A5.24,5.24,0,0,1,23.69,1.5Z" />
                                <path className="cls-detail-0" d="M6.69,18.25H9.31a5.24,5.24,0,0,1,5.19,5.29v2.67A5.24,5.24,0,0,1,9.31,31.5H6.69A5.24,5.24,0,0,1,1.5,26.21V23.54A5.24,5.24,0,0,1,6.69,18.25Z" />
                                <path className="cls-detail-0" d="M23.69,18.25h2.62a5.24,5.24,0,0,1,5.19,5.29v2.67a5.24,5.24,0,0,1-5.19,5.29H23.69a5.24,5.24,0,0,1-5.19-5.29V23.54A5.24,5.24,0,0,1,23.69,18.25Z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="20px" height="20px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33">
                <defs>
                    <style>{`.cls-detail-0b{fill:none;stroke:#fff;stroke-miterlimit:10;stroke-width:3px;}`}</style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <g id="Layer_2-2" data-name="Layer 2">
                            <g id="Layer_1-2-2" data-name="Layer 1-2">
                                <path className="cls-detail-0b" d="M6.69,1.5H9.31A5.24,5.24,0,0,1,14.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H6.69A5.24,5.24,0,0,1,1.5,9.46V6.79A5.24,5.24,0,0,1,6.69,1.5Z" />
                                <path className="cls-detail-0b" d="M23.69,1.5h2.62A5.24,5.24,0,0,1,31.5,6.79V9.46a5.24,5.24,0,0,1-5.19,5.29H23.69A5.24,5.24,0,0,1,18.5,9.46V6.79A5.24,5.24,0,0,1,23.69,1.5Z" />
                                <path className="cls-detail-0b" d="M6.69,18.25H9.31a5.24,5.24,0,0,1,5.19,5.29v2.67A5.24,5.24,0,0,1,9.31,31.5H6.69A5.24,5.24,0,0,1,1.5,26.21V23.54A5.24,5.24,0,0,1,6.69,18.25Z" />
                                <path className="cls-detail-0b" d="M23.69,18.25h2.62a5.24,5.24,0,0,1,5.19,5.29v2.67a5.24,5.24,0,0,1-5.19,5.29H23.69a5.24,5.24,0,0,1-5.19-5.29V23.54A5.24,5.24,0,0,1,23.69,18.25Z" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheTout