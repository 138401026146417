import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import UserContext from '../context/UserContext'
import '../css/Navbar.css'
import AlsalamLogo from '../svg/AlsalamLogo'
import DiarDzairLogo from '../svg/DiarDzairLogo'

function Navbar() {
    const { user, setUser, itemActive, setItemActive, filterParams, setFilterParams } = useContext(UserContext);
    const navigate = useNavigate();

    const logoutFunction = () => {
        setUser(null)
        setItemActive('') 
        setFilterParams('')
        localStorage.removeItem('userInfo');
        localStorage.removeItem('itemActive');
        localStorage.removeItem('filterParams');
        navigate('/banque/login');
    }

    const itemActiveHandler = (value) =>{
        setItemActive(value)
        localStorage.setItem('itemActive',value)
    }



    return (
        <div className='navbar-section'>
            <div className="row">
                <div className="col-md-12 col-12">
                    <div className="header">
                        <DiarDzairLogo widthSize='99px' heightSize='76px' />
                        <ul className='items'>
                            <li>
                                <Link to={`/banque/`} className={itemActive === "acceuil" ? "item-active" : ""} onClick={()=>itemActiveHandler("acceuil")} >
                                    Acceuil
                                </Link>
                            </li>
                            <li>
                                <Link to={`/banque/stock`} className={itemActive === "stock" ? "item-active" : ""}  onClick={()=>itemActiveHandler("stock")}>
                                    Stock
                                </Link>
                            </li>
                            <li>
                                <Link to={`/banque/statistiques`} className={itemActive === "statistiques" ? "item-active" : ""}  onClick={()=>itemActiveHandler("statistiques")}>
                                    Tableau de Bord
                                </Link>
                            </li>
                        </ul>
                        <div className="header-content">
                            <div className='alsalam-logo'>
                                <AlsalamLogo widthSize='65px' heightSize='100px' />
                            </div>
                            <div className="user-info">
                                <h1>{user.user}<br /> <span className='small'>{user.roles[user.roles.length - 1]}</span></h1>
                                <svg width="50px" height="50px" xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 59 59">
                                    <defs>
                                        <style>
                                            {`
                                        .cls-navbar-nav11 {
                                            fill: #496656;
                                                }

                                        .cls-navbar-nav22 {
                                            fill: none;
                                        stroke: #496656;
                                        stroke-miterlimit: 10;
                                        stroke-width: 4px;
                                                }
                                       `}
                                        </style>
                                    </defs>
                                    <g id="Layer_2" data-name="Layer 2">
                                        <g id="Layer_1-2" data-name="Layer 1">
                                            <ellipse className="cls-navbar-nav11" cx="29.5" cy="16.96" rx="7.11" ry="6.7" />
                                            <path className="cls-navbar-nav11"
                                                d="M45.32,42.89H15.52C16.86,33,23,25.52,30.42,25.52S44,33,45.32,42.89Z" />
                                            <circle className="cls-navbar-nav22" cx="29.5" cy="29.5" r="27.5" />
                                        </g>
                                    </g>
                                </svg>
                                <ul className='logout-box'>
                                    <li>
                                        <svg width="120px" height="150px" style={{ display: 'inline-block' }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 164 194">
                                            <defs>
                                                <style>
                                                    {`.cls-navbar-1{fill:#496656;}.cls-navbar-2{fill:none;stroke:#496656;stroke-miterlimit:10;stroke-width:4px;}`}
                                                </style>
                                            </defs>
                                            <g id="Layer_2" data-name="Layer 2">
                                                <g id="Layer_1-2" data-name="Layer 1">
                                                    <g id="Layer_2-2" data-name="Layer 2">
                                                        <g id="Layer_1-2-2" data-name="Layer 1-2">
                                                            <ellipse className="cls-navbar-1" cx="82" cy="53.68" rx="20.68" ry="23.15" /><path className="cls-navbar-1" d="M128,143.26H41.33c3.9-34.17,21.76-60,43.35-60S124.18,109.09,128,143.26Z" /><ellipse className="cls-navbar-2" cx="82" cy="97" rx="80" ry="95" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                        <h2>{user.user}<br /> <span>Role : {user.roles[user.roles.length - 1]}</span></h2>
                                    </li>
                                    <li>
                                        <div className='logout-btn'>
                                            <p onClick={logoutFunction}>
                                                <svg width="25px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                                                    <defs>
                                                        <style>{`.cls-navbar-3{fill:#fff;}`}</style>
                                                    </defs>
                                                    <g id="Layer_2" data-name="Layer 2">
                                                        <g id="Layer_1-2" data-name="Layer 1">
                                                            <g id="Layer_2-2" data-name="Layer 2">
                                                                <g id="Layer_1-2-2" data-name="Layer 1-2">
                                                                    <path className="cls-navbar-3" d="M10,28.75V20a1.26,1.26,0,0,1,2.5-.19.6.6,0,0,1,0,.19v7.5h15V2.5h-15V10a1.21,1.21,0,0,1-1.15,1.28A1.24,1.24,0,0,1,10,10.18V1.25A1.19,1.19,0,0,1,11.11,0H28.75A1.19,1.19,0,0,1,30,1.11a.61.61,0,0,1,0,.14v27.5A1.18,1.18,0,0,1,28.88,30H11.26A1.2,1.2,0,0,1,10,28.88Z" /><path className="cls-navbar-3" d="M0,15a1.18,1.18,0,0,1,1.12-1.25H20a1.2,1.2,0,0,1,1.26,1.12V15a1.19,1.19,0,0,1-1.11,1.25H1.26A1.2,1.2,0,0,1,0,15.13Z" /><path className="cls-navbar-3" d="M.38,15.88a1.22,1.22,0,0,1,0-1.76l5-5A1.21,1.21,0,0,1,7,9l.1.1a1.21,1.21,0,0,1,0,1.75l-5,5a1.14,1.14,0,0,1-.87.37A1.15,1.15,0,0,1,.38,15.88Z" /><path className="cls-navbar-3" d="M5.38,20.87l-5-5a1.22,1.22,0,0,1,0-1.76A1.21,1.21,0,0,1,2,14l.1.09,5,5a1.21,1.21,0,0,1,0,1.75,1.15,1.15,0,0,1-.87.38A1.12,1.12,0,0,1,5.38,20.87Z" />
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                                <span>Logout</span>
                                            </p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar