import React, { useContext, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import Label from '../../svg/Label';
import { useEffect } from 'react';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import UserContext from '../../context/UserContext';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

function NbrCommandWilaya() {
    const { user, setUser } = useContext(UserContext);

    const [nbrCommandWilayaDate, setNbrCommandWilayaDate] = useState("aujourdhui")
    const [nbrCommandWilayaOrder, setNbrCommandWilayaOrder] = useState("wilaya")

    const setFilterDate = (filter_type) => {
        setNbrCommandWilayaDate(filter_type)
    }
    const setFilterOrder = (filter_type) => {
        setNbrCommandWilayaOrder(filter_type)
    }

    useEffect(() => {
        getNbrCommandWilaya();
    }, [])
    
    const getNbrCommandWilaya = async()=>{ 
        var model = { username: user.user, orderId: user.orderId };      
        await axios
            .post(`https://diardzair.com.dz/api/taksit/rest/get/formDataStat`,JSON.stringify(model),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        // 'Content-Type': 'application/json'
                    },
                })
            .then((res) => {
                console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <div className='box'>
            <h2>
                <Label widthSize={"20px"} />
                <span>Nombre de Commande par Wilaya :</span>
            </h2>
            <div className='statistique-content full-width'>
                <Bar
                    width={600}
                    height={400}
                    options={{ maintainAspectRatio: false }}
                    data={{
                        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'March', 'April', 'May', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'March', 'April', 'May', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'March', 'April', 'May', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'March', 'April', 'May', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'March', 'April', 'May', 'March', 'April', 'May', 'June', 'July', 'March', 'April', 'May'],
                        datasets: [
                            {
                                label: 'Nombre de Commande par Wilaya',
                                data: [10, 20, 30, 40, 50, 60, 70, 30, 40, 50, 10, 20, 30, 40, 50, 60, 70, 30, 40, 50, 10, 20, 30, 40, 50, 60, 70, 30, 40, 50, 10, 20, 30, 40, 50, 60, 70, 30, 40, 50, 10, 20, 30, 40, 50, 60, 70, 30, 40, 50, 30, 40, 50, 60, 70, 30, 40, 50,],
                                backgroundColor: 'rgba(75, 192, 192, 1)',
                            },
                        ],
                    }}
                />
            </div>
            <div className='filter'>
                <div>
                    <ul>
                        <li className={nbrCommandWilayaDate === "aujourdhui" ? "filter-active" : ""} onClick={() => setFilterDate('aujourdhui')}>Aujourd'hui</li>
                        <li className={nbrCommandWilayaDate === "mois" ? "filter-active" : ""} onClick={() => setFilterDate('mois')}>Mois_En_Cours</li>
                        <li className={nbrCommandWilayaDate === "annee" ? "filter-active" : ""} onClick={() => setFilterDate('annee')}>Année_En_Cours</li>
                        <li className={nbrCommandWilayaDate === "intervalle" ? "filter-active" : ""} onClick={() => setFilterDate('intervalle')}>
                            Intervalle
                            <div className='interval-pop-up'>
                                <div className='row'>
                                    <div className="form-group col-lg-6">
                                        <label>Date Debut</label>
                                        <input type="date" className="form-control" placeholder="Date Debut" required />
                                    </div>
                                    <div className="form-group col-lg-6">
                                        <label>Date Fin</label>
                                        <input type="date" className="form-control" placeholder="Date Fin" required />
                                    </div>
                                    <div className='text-center'>
                                    <span onClick={() => setFilterDate('intervalle')}>Valider</span>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div>
                    <ul>
                        <li className={nbrCommandWilayaOrder === "wilaya" ? "filter-active" : ""} onClick={() => setFilterOrder('wilaya')}>Ordonner_Par_Wilaya</li>
                        <li className={nbrCommandWilayaOrder === "nbrcommand" ? "filter-active" : ""} onClick={() => setFilterOrder('nbrcommand')}>Ordonner_Par_Nombre_De_Commandes</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default NbrCommandWilaya