import React, { useContext } from 'react'
import axios from 'axios';
import UserContext from '../context/UserContext';
import { useState } from 'react';
import moment from 'moment'

function DossierGarantieModal({hash , idModal, check, setCheck}) {
    const { user, setUser } = useContext(UserContext);
    const [dateDB, setDateDB] = useState(moment().format('YYYYY-MM-DD'));

    const onCheckDossierBanque = async () => {
        await axios.post(`https://diardzair.com.dz/api/taksit/rest/set/activeDossierBanqueGarantie`,
             JSON.stringify({ username: user.user, orderId:  user.orderId, hash: hash, date:moment(dateDB).format('DD/MM/YYYY') }),
             {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'Content-Type': 'application/json'
                },
            })
            .then(res => {
                console.log('Successs');
                console.log(res)
                setCheck(!check);
            }).catch(err => {
                console.log(err)
            })
        // console.log(moment(dateDB).format('DD/MM/YYYY'));
    }

  return (
    <div className="modal fade" id={`${idModal}`} tabIndex="-1" aria-labelledby={`${idModal}Label`}
        aria-hidden="true">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id={`${idModal}Label`}>
                        {/* Message de Confirmation */}
                        Voulez vous vraiment accuser la réception dossier garantie?
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="my-3">
                        <label htmlFor="">Date de validation</label>
                        <input type='date' name='dateDB' value={dateDB} onChange={e=>setDateDB(e.target.value)} className='form-control' />
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={() => onCheckDossierBanque()}>Oui</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Non</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default DossierGarantieModal