import React from 'react'

function Label({widthSize = "50px"}) {
    return (
        <svg width={widthSize} height="25px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <defs><style>{`.label-cls-1{fill:#000000;}`}</style></defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Flat">
                    <path className="label-cls-1" d="M15.91,11.82H13.64V8.18h2.27a4.09,4.09,0,1,0-4.09-4.09V6.36H8.18V4.09A4.09,4.09,0,1,0,4.09,8.18H6.36v3.64H4.09a4.09,4.09,0,1,0,4.09,4.09V13.64h3.64v2.27a4.09,4.09,0,1,0,4.09-4.09ZM13.64,4.09a2.27,2.27,0,1,1,2.27,2.27H13.64Zm-11.82,0a2.27,2.27,0,0,1,4.54,0V6.36H4.09A2.27,2.27,0,0,1,1.82,4.09ZM6.36,15.91a2.27,2.27,0,1,1-2.27-2.27H6.36ZM8.18,8.18h3.64v3.64H8.18Zm7.73,10a2.27,2.27,0,0,1-2.27-2.27V13.64h2.27a2.27,2.27,0,0,1,0,4.54Z" />
                </g>
            </g>
        </svg>

    )
}

export default Label