import axios from 'axios';
import fileDownload from 'js-file-download';
import React, { useEffect, useRef, useState } from 'react';
import { useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom'
import LoadingComponent from '../components/LoadingComponent';
import UserContext from '../context/UserContext';
import '../css/StockPage.css';
import Search from '../svg/Search';
import $ from 'jquery';
import ArrowBottom from '../svg/ArrowBottom';

function StockPage() {
    const { user, setUser, itemActive, setItemActive } = useContext(UserContext);

    const [num, setNum] = useState("");
    const [lot, setLot] = useState("");
    const [codeOpr, setCodeOpr] = useState("");
    const [designation, setDesignation] = useState("");
    const [definition, setDefinition] = useState("");
    const [couleur, setCouleur] = useState("");
    const [prixAchat, setPrixAchat] = useState("");
    const [referenceIs, setReferenceIs] = useState("");
    const [referenceLd, setReferenceLd] = useState("");
    const [categorie, setCategorie] = useState("");
    const [referenceJira, setReferenceJira] = useState("");
    const [dateVente, setDateVente] = useState("");
    const [clientNom, setClientNom] = useState("");
    const [numeroCompte, setNumeroCompte] = useState("");
    const [clientEmail, setClientEmail] = useState("");
    const [clientAdresse, setClientAdresse] = useState("");
    const [statutVehicule, setStatutVehicule] = useState("");
    const [agence, setAgence] = useState("");
    const [inputter, setInputter] = useState("");
    const [concessionnaire, setConcessionnaire] = useState("");
    const [entreprise, setEntreprise] = useState("");
    const [commandeSovac, setCommandeSovac] = useState("");
    const [telephone, setTelephone] = useState("");
    const [referenceClient, setReferenceClient] = useState("");
    const [matricule, setMatricule] = useState("");
    const [matriculeTmp, setMatriculeTmp] = useState("");

    const { page = 1 } = useParams();
    const navigate = useNavigate();

    const [demandes, setDemandes] = useState([]);
    const [tableShow, setTableShow] = useState(false);
    const [pages, setPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const [width, setWidth] = useState(0);
    const listRef = useRef();

    const [filterPopup, setFilterPopup] = useState(true);
    const [statisticPopup, setStatisticPopup] = useState(true);
    const [statisticInfo, setStatisticInfo] = useState(null);

    useEffect(() => {
        setItemActive('stock');
        localStorage.setItem('itemActive', 'stock');
    }, [])

    useEffect(() => {
        getCommands();
        getStatistic();
    }, [page])

    useEffect(() => {
        if (tableShow == true && (demandes?.length !== 0)) {
            setWidth(listRef.current.scrollWidth);
        }else{
            setWidth(0)
        }
    }, [tableShow, demandes])

    useEffect(() => {
        if (parseInt(page) <= parseInt(pages) - 3) {
            setPageNumber(parseInt(page));
        } else {
            setPageNumber(parseInt(pages) - 3)
        }
    }, [page, pages])

    useEffect(() => {
        $(function () {
            $(".wrapper").scroll(function () {
                $(".table")
                    .scrollLeft($(".wrapper").scrollLeft());
            });
            $(".table").scroll(function () {
                $(".wrapper")
                    .scrollLeft($(".table").scrollLeft());
            });
        });
    }, [])

    const logoutFunction = () => {
        setUser(null)
        setItemActive('')
        localStorage.removeItem('userInfo');
        localStorage.removeItem('itemActive');
    }

    const onSearch = () => {
        getCommands();
    }
    const downloadAll = async () => {
        var current = new Date();
        var day = current.getDate();
        var month = current.getMonth() + 1;
        var year = current.getFullYear();
        var df = year.toString().substring(2) + '' + month + '' + day;

        // var model = {
        //     num: id, designation, nomClient, category, matricule, matriculeTmp, username: user.user,
        //     orderId: user.orderId, dd: '191015', df: df,
        // }
        var model = {
            num, lot, codeOpr, designation, definition, couleur, prixAchat, referenceIs, referenceLd, categorie, referenceJira, dateVente, clientNom,
             numeroCompte, clientEmail, clientAdresse, statutVehicule, agence, inputter, concessionnaire, entreprise, commandeSovac, telephone, 
             referenceClient, matricule, matriculeTmp, username: user.user, orderId: user.orderId, dd: '191015', df: df,
        }

        await axios
            // .post('https://diardzair.com.dz/api/taksit/rest/get/formDataFile')
            .post(`https://diardzair.com.dz/api/taksit/rest/get/formDataStockFile`, JSON.stringify(model),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        // 'Content-Type': 'application/json'
                    },
                },
                { responseType: 'blob' },
            )
            .then((res) => {
                fileDownload(res.data, 'stock_file.csv');
            })
            .catch((err) => {
                console.log(err);
            });
    }
    function getDate(dd) {
        var s = new Date(dd.timestamp * 1000).toLocaleDateString("fr-FR")
        return s;
    }
    const getStatistic = async ()=>{
        var model = {
            username: user.user, orderId: user.orderId,
        }
        await axios.post(`https://www.diardzair.com.dz/api/taksit/rest/get/statutVehicule/`,JSON.stringify(model), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
                // 'Content-Type': 'application/json'
            },
        }).then((res) => {
            if (res.data.errorCode === 7) {
                logoutFunction();
            }
            // console.log(res.data);
            setStatisticInfo(res.data);
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const getCommands = async () => {
        setTableShow(false);
        var current = new Date();
        var day = current.getDate();
        var month = current.getMonth() + 1;
        var year = current.getFullYear();
        var df = year.toString().substring(2) + '' + month + '' + day;

        var model = {
            num, lot, codeOpr, designation, definition, couleur, prixAchat, referenceIs, referenceLd, categorie, referenceJira, dateVente, clientNom,
             numeroCompte, clientEmail, clientAdresse, statutVehicule, agence, inputter, concessionnaire, entreprise, commandeSovac, telephone, 
             referenceClient, matricule, matriculeTmp, username: user.user, orderId: user.orderId, dd: '191015', df: df,
        }

        await axios
            .post(`https://www.diardzair.com.dz/api/taksit/rest/get/formDataStock?pageSize=${pageSize}&page=${page}`, JSON.stringify(model),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                        // 'Content-Type': 'application/json'
                    },
                })
            .then((res) => {
                if (res.data.errorCode === 7) {
                    logoutFunction();
                }
                setTableShow(true);
                setDemandes(res.data["0"]);
                setPages(res.data.pageTotal);
                // console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
                setTableShow(true);
            });

    }
    const goToDetailDemandHandler = async(num_chasse,status_stock) => {
        if(status_stock === 'en_stock'){
            return false;
        }
        var model = {username: user.user, orderId: user.orderId, num: num_chasse};
        await axios.post('https://www.diardzair.com.dz/api/taksit/rest/get/creditDemandesByNum',JSON.stringify(model),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                    // 'Content-Type': 'application/json'
                },
            })
            .then((res) => {
                var hash = res.data['hash'];
                navigate(`/banque/detail/${hash}`);
            })
            .catch((err) => {
                console.log(err);
            });
    }
    const checkSize = (value) => {
        if (value.length > 10)
            return value.substring(0, 7) + '...';
        else
            return value;
    }
    function onclickNext() {
        if (page < pages) {
            navigate(`/banque/stock/page/${parseInt(page) + 1}`)
        }
    }
    function onclickPrevious() {
        if (page > 1) {
            navigate(`/banque/stock/page/${parseInt(page) - 1}`)
        }
    }

    return (
        <div className='stock-page'>
            <div className={filterPopup ? `filter-section active` : 'filter-section'}>
                <h2>Filtrage</h2>
                <span className='popup' onClick={() => setFilterPopup(t=>!t)}>
                    <ArrowBottom />
                </span>
                <div className="search-advanced-section">
                    <div className="row gy-3">
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Num</label>
                            <input className="form-control" placeholder="Num" type="text" id='num'
                                name='num' value={num} onChange={e => setNum(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Lot</label>
                            <input className="form-control" placeholder="Lot" type="text" id='lot'
                                name='lot' value={lot} onChange={e => setLot(e.target.value)} />
                        </div> 
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Code Opr</label>
                            <input className="form-control" placeholder="Code Opr" type="text" id='codeOpr'
                                name='codeOpr' value={codeOpr} onChange={e => setCodeOpr(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Designation</label>
                            <input type="text" className="form-control" placeholder="Designation" id='designation' value={designation}
                               name='designation' onChange={e => setDesignation(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Définition</label>
                            <input type="text" className="form-control" placeholder="Définition" id='definition' value={definition}
                               name='definition'  onChange={e => setDefinition(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Couleur</label>
                            <input className="form-control" placeholder="Couleur" type="text" id='couleur'
                                name='couleur' value={couleur} onChange={e => setCouleur(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Prix Achat</label>
                            <input type="text" className="form-control" placeholder="Prix Achat" id='prixAchat' value={prixAchat}
                                name='prixAchat' onChange={e => setPrixAchat(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Reference Is</label>
                            <input type="text" className="form-control" placeholder="Reference Is" id='referenceIs' value={referenceIs}
                                name='referenceIs' onChange={e => setReferenceIs(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Reference Ld</label>
                            <input type="text" className="form-control" placeholder="Reference Ld" id='referenceLd' value={referenceLd}
                                name='referenceLd' onChange={e => setReferenceLd(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Categorie</label>
                            <input type="text" className="form-control" placeholder="Categorie" id='categorie' value={categorie}
                                name='categorie' onChange={e => setCategorie(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Reference Jira</label>
                            <input type="text" className="form-control" placeholder="Reference Jira" id='referenceJira' value={referenceJira}
                                name='referenceJira' onChange={e => setReferenceJira(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Date Vente</label>
                            <input type="text" className="form-control" placeholder="Date Vente" id='dateVente' value={dateVente}
                                name='dateVente' onChange={e => setDateVente(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Client Nom</label>
                            <input type="text" className="form-control" placeholder="Client Nom" id='clientNom' value={clientNom}
                                name='clientNom' onChange={e => setClientNom(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Numero Compte</label>
                            <input type="text" className="form-control" placeholder="Numero Compte" id='numeroCompte' value={numeroCompte}
                                name='numeroCompte' onChange={e => setNumeroCompte(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Client Email</label>
                            <input type="text" className="form-control" placeholder="Client Email" id='clientEmail' value={clientEmail}
                                name='clientEmail' onChange={e => setClientEmail(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Client Adresse</label>
                            <input type="text" className="form-control" placeholder="Client Adresse" id='clientAdresse' value={clientAdresse}
                                name='clientAdresse' onChange={e => setClientAdresse(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Statut Vehicule</label>
                            <input type="text" className="form-control" placeholder="Statut Vehicule" id='statutVehicule' value={statutVehicule}
                                name='statutVehicule' onChange={e => setStatutVehicule(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Agence</label>
                            <input type="text" className="form-control" placeholder="Agence" id='agence' value={agence}
                                name='agence' onChange={e => setAgence(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Inputter</label>
                            <input type="text" className="form-control" placeholder="Inputter" id='inputter' value={inputter}
                                name='inputter' onChange={e => setInputter(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Concessionnaire</label>
                            <input type="text" className="form-control" placeholder="Concessionnaire" id='concessionnaire' value={concessionnaire}
                                name='concessionnaire' onChange={e => setConcessionnaire(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Entreprise</label>
                            <input type="text" className="form-control" placeholder="Entreprise" id='entreprise' value={entreprise}
                                name='entreprise' onChange={e => setEntreprise(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Commande Sovac</label>
                            <input type="text" className="form-control" placeholder="Commande Sovac" id='commandeSovac' value={commandeSovac}
                                name='commandeSovac' onChange={e => setCommandeSovac(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Telephone</label>
                            <input type="text" className="form-control" placeholder="Telephone" id='telephone' value={telephone}
                                name='telephone' onChange={e => setTelephone(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Reference Client</label>
                            <input type="text" className="form-control" placeholder="Reference Client" id='referenceClient' value={referenceClient}
                                name='referenceClient' onChange={e => setReferenceClient(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Matricule</label>
                            <input className="form-control" placeholder="Matricule" type="text" id='matricule'
                                name='matricule' value={matricule} onChange={e => setMatricule(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12">
                            <label>Matricule Tmp</label>
                            <input type="text" className="form-control" placeholder="Matricule Tmp" id='matriculeTmp' value={matriculeTmp}
                                onChange={e => setMatriculeTmp(e.target.value)} />
                        </div>
                        <div className="form-group col-lg-2 col-md-4 col-sm-12 position-relative">
                            <span className="btn absolute-bottom-right" onClick={() => onSearch()}>Recherche</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={statisticPopup ? `statistic-section active` : 'statistic-section'}>
                <h2>Statistique</h2>
                <span className='popup' onClick={() => setStatisticPopup(t=>!t)}>
                    <ArrowBottom />
                </span>
                <div className="search-advanced-section">
                    <div className="row gy-3">
                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                            <label>Montant Reserve</label><br />
                            <span>{statisticInfo && (statisticInfo.reserve[0].mt ? statisticInfo.reserve[0].mt : '0.00')} DA</span>
                        </div>
                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                            <label>Montant Vendu</label><br />
                            <span>{statisticInfo && (statisticInfo.vendu[0].mt ? statisticInfo.vendu[0].mt : '0.00')} DA</span>
                        </div>
                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                            <label>Montant En Stock</label><br />
                            <span>{statisticInfo && (statisticInfo.en_stock[0].mt ? statisticInfo.en_stock[0].mt : '0.00')} DA</span>
                        </div>
                    </div>
                    <div className="row gy-3">
                         <div className="form-group col-lg-3 col-md-4 col-sm-12">
                            <label>Total Reserve</label><br />
                            <span>{statisticInfo && (statisticInfo.reserve_tt[0].mt ? statisticInfo.reserve_tt[0].mt : '0')}</span>
                        </div>
                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                            <label>Total Vendu</label><br />
                            <span>{statisticInfo && (statisticInfo.vendu_tt[0].mt ? statisticInfo.vendu_tt[0].mt : '0')}</span>
                        </div>
                        <div className="form-group col-lg-3 col-md-4 col-sm-12">
                            <label>Total En Stock</label><br />
                            <span>{statisticInfo && (statisticInfo.en_stock_tt[0].mt ? statisticInfo.en_stock_tt[0].mt : '0')} </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="info-section">
                <h2>Tableau de Stock</h2>
                <div className='download-table'>
                    <span onClick={downloadAll}>Telecharger Ici</span>
                </div>
                <div className='wrapper'>
                    <div className='div1' style={{ width: `${width}px` }}></div>
                </div>
                {
                    // Begin Table
                    tableShow ?
                        (demandes?.length !== 0) ?
                            <table className="table" ref={listRef}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Num</th>
                                        <th>Lot</th>
                                        <th>Code Opr</th>
                                        <th>DESIGNATION</th>
                                        <th>DEFINITION</th>
                                        <th>COULEUR</th>
                                        <th>PRIX.ACHAT</th>
                                        <th>Reference Is</th>
                                        <th>Reference Ld</th>
                                        <th>Categorie</th>
                                        <th>Reference Jira</th>
                                        <th>Date Vente</th>
                                        <th>Nom Client</th>
                                        <th>Numéro Compte</th>
                                        <th>Email Client</th>
                                        <th>Adresse Client</th>
                                        <th>Statut Vehicule</th>
                                        <th>Agence</th>
                                        <th>Inputter</th>
                                        <th>Concessionnaire</th>
                                        <th>Entreprise</th>
                                        <th>Commande Sovac</th>
                                        <th>Telephone</th>
                                        <th>Reférence Client</th>
                                        <th>Matricule</th>
                                        <th>Matricule Tmp</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        demandes?.map((demande, index) => (
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{demande.num}</td>
                                                <td>{demande.lot}</td>
                                                <td>{demande.codeOpr}</td>
                                                <td>{demande.designation}</td>
                                                <td>{demande.definition}</td>
                                                <td>{demande.couleur}</td>
                                                <td>{demande.prixAchat}</td>
                                                <td>{demande.referenceIs}</td>
                                                <td>{demande.referenceLd}</td>
                                                {/* <td>{demande.}</td> */}
                                                <td>{demande.categorie}</td>
                                                <td>{demande.referenceJira}</td>
                                                <td>{demande.dateVente /*? getDate(demande.dateVente) : ''*/}</td>
                                                <td>{demande.clientNom}</td>
                                                <td>{demande.numeroCompte}</td>                                             
                                                <td>{demande.clientEmail}</td>
                                                <td>{demande.clientAdresse}</td>
                                                <td onClick={() => goToDetailDemandHandler(demande.num,demande.statutVehicule)}>
                                                    <span className={`stock-status stock-status-${demande.statutVehicule}`}>
                                                        {demande.statutVehicule}
                                                    </span>
                                                </td>
                                                <td>{demande.agence}</td>
                                                <td>{demande.inputter}</td>
                                                <td>{demande.concessionnaire}</td>
                                                <td>{demande.entreprise}</td>
                                                <td>{demande.commandeSovac}</td>
                                                <td>{demande.telephone}</td>
                                                <td>{demande.referenceClient}</td>
                                                <td>{demande.matricule}</td>
                                                <td>{demande.matriculeTmp}</td>

                                                <td onClick={() => goToDetailDemandHandler(demande.num,demande.statutVehicule)}>
                                                    <Search />
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                            : <div className='no-data-wrapper'>Pas de Données Disponibles</div>
                        : (
                            <LoadingComponent />
                        )
                    //  End Table                  
                }
                {/* Begin Pagination */}
                {
                    (tableShow && pages && pages > 1) ? (
                        <div className="pagination">
                            {
                                parseInt(pages) <= 8 ? (
                                    <div className="pagination-content">
                                        <span onClick={onclickPrevious}>
                                            <i className="fa-solid fa-angle-left"></i>
                                        </span>
                                        <span onClick={() => navigate(`/banque/stock/page/${1}`)} > Premier </span>
                                        {
                                            [...Array(pages).keys()].map(x => (
                                                <span key={x + 1} className={x + 1 == page ? 'page-number-active' : ''} onClick={() => navigate(`/banque/stock/page/${x + 1}`)}>
                                                    {x + 1}
                                                </span>
                                            ))
                                        }
                                        <span onClick={() => navigate(`/banque/stock/page/${pages}`)} > Dernier </span>
                                        <span onClick={onclickNext} >
                                            <i className="fa-solid fa-angle-right"></i>
                                        </span>
                                    </div>
                                ) :
                                    (
                                        <div className="pagination-content">
                                            <span onClick={onclickPrevious}>
                                                <i className="fa-solid fa-angle-left"></i>
                                            </span>
                                            <span onClick={() => navigate(`/banque/stock/page/${1}`)} > Premier </span>
                                            {
                                                (pageNumber - 1 > 0) && (
                                                    <span onClick={() => navigate(`/banque/stock/page/${pageNumber - 1}`)} > {pageNumber - 1} </span>
                                                )
                                            }
                                            {
                                                pageNumber && (
                                                    <span className={pageNumber == page ? 'page-number-active' : ''} onClick={() => navigate(`/banque/stock/page/${pageNumber}`)}>
                                                        {pageNumber}
                                                    </span>
                                                )
                                            }
                                            {
                                                (pageNumber + 1 <= pages) && (
                                                    <span className={pageNumber + 1 == page ? 'page-number-active' : ''} onClick={() => navigate(`/banque/stock/page/${pageNumber + 1}`)}>
                                                        {pageNumber + 1}
                                                    </span>
                                                )
                                            }
                                            {
                                                (pageNumber === 1) && (
                                                    <span onClick={() => navigate(`/banque/stock/page/${pageNumber + 2}`)}>{pageNumber + 2}</span>
                                                )
                                            }
                                            {
                                                (pageNumber + 1 !== pages - 2) && (
                                                    <span>{'....'}</span>
                                                )
                                            }
                                            <span onClick={() => navigate(`/banque/stock/page/${pages - 1}`)} className={pages - 1 == page ? 'page-number-active' : ''}>
                                                {pages - 1}
                                            </span>
                                            <span onClick={() => navigate(`/banque/stock/page/${pages}`)} className={pages == page ? 'page-number-active' : ''}>
                                                {pages}
                                            </span>
                                            <span onClick={() => navigate(`/banque/stock/page/${pages}`)} > Dernier </span>
                                            <span onClick={onclickNext} >
                                                <i className="fa-solid fa-angle-right"></i>
                                            </span>
                                        </div>
                                    )
                            }
                        </div>
                    ) : ""
                }
                {/* End Pagination */}

            </div>
        </div>
    )
}

export default StockPage