import React from 'react'

function TacheSuiviDossier({color}) {
    return (
        color === 'no-color' ? (
            <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 35">
                <defs>
                    <style>
                        {`
                            .cls-1 {
                            fill: #535353;
                            }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_4" data-name="Layer 4">
                        <path className="cls-1"
                            d="M20.37,35a3.63,3.63,0,0,0,.69-.08,6.61,6.61,0,0,0,1,.08,8,8,0,0,0,3.14-15.28V8.53a1,1,0,0,0-.29-.7L24,7l-.1-.12L17.4.29A1,1,0,0,0,16.7,0H9.76a1,1,0,0,0,0,2h6a1.49,1.49,0,0,0-.06.43V6.56A2.93,2.93,0,0,0,18.65,9.5h4.17a1.25,1.25,0,0,0,.43-.07v9.71h-.11A7.06,7.06,0,0,0,22.05,19a8,8,0,0,0-7.94,8,8.22,8.22,0,0,0,.17,1.58c0,.17.09.32.13.48a7.81,7.81,0,0,0,.35,1c.07.16.14.32.22.48a7.91,7.91,0,0,0,.56.93c.09.13.17.27.27.39a7.7,7.7,0,0,0,1,1.06l.05.06h-12a2.89,2.89,0,0,1-2.87-2.92V4.87A2.89,2.89,0,0,1,4.81,2H6.43A1,1,0,0,0,6.43,0H4.81A4.84,4.84,0,0,0,0,4.87V30.13A4.84,4.84,0,0,0,4.81,35ZM18.65,7.55a1,1,0,0,1-1-1V3.32l4.21,4.23ZM16.05,27a6,6,0,0,1,6-6.06,6.51,6.51,0,0,1,1.48.2l.41.11A6.11,6.11,0,0,1,28.06,27a6,6,0,0,1-6,6,6.43,6.43,0,0,1-1-.07l-.22,0A6,6,0,0,1,16.05,27Z" />
                        <path className="cls-1"
                            d="M20.61,29.25a1,1,0,0,0,.68.29,1,1,0,0,0,.69-.29L25,26.17a1,1,0,1,0-1.37-1.37l-2.38,2.39-.85-.85a1,1,0,0,0-1.37,1.37Z" />
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 35">
                <defs>
                    <style>
                        {`
                            .cls-b {
                                fill: #fff;
                                }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_4" data-name="Layer 4">
                        <path className="cls-b"
                            d="M20.37,35a3.63,3.63,0,0,0,.69-.08,6.61,6.61,0,0,0,1,.08,8,8,0,0,0,3.14-15.28V8.53a1,1,0,0,0-.29-.7L24,7l-.1-.12L17.4.29A1,1,0,0,0,16.7,0H9.76a1,1,0,0,0,0,2h6a1.49,1.49,0,0,0-.06.43V6.56A2.93,2.93,0,0,0,18.65,9.5h4.17a1.25,1.25,0,0,0,.43-.07v9.71h-.11A7.06,7.06,0,0,0,22.05,19a8,8,0,0,0-7.94,8,8.22,8.22,0,0,0,.17,1.58c0,.17.09.32.13.48a7.81,7.81,0,0,0,.35,1c.07.16.14.32.22.48a7.91,7.91,0,0,0,.56.93c.09.13.17.27.27.39a7.7,7.7,0,0,0,1,1.06l.05.06h-12a2.89,2.89,0,0,1-2.87-2.92V4.87A2.89,2.89,0,0,1,4.81,2H6.43A1,1,0,0,0,6.43,0H4.81A4.84,4.84,0,0,0,0,4.87V30.13A4.84,4.84,0,0,0,4.81,35ZM18.65,7.55a1,1,0,0,1-1-1V3.32l4.21,4.23ZM16.05,27a6,6,0,0,1,6-6.06,6.51,6.51,0,0,1,1.48.2l.41.11A6.11,6.11,0,0,1,28.06,27a6,6,0,0,1-6,6,6.43,6.43,0,0,1-1-.07l-.22,0A6,6,0,0,1,16.05,27Z" />
                        <path className="cls-b"
                            d="M20.61,29.25a1,1,0,0,0,.68.29,1,1,0,0,0,.69-.29L25,26.17a1,1,0,1,0-1.37-1.37l-2.38,2.39-.85-.85a1,1,0,0,0-1.37,1.37Z" />
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheSuiviDossier