import React from 'react'

function TacheControleDossierPhysiqueBanque({color}) {
    return (
        color === 'no-color' ? (
            <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                <defs>
                    <style>
                        {`
                            .cls-1 {
                                fill: #535353;
                                }
                        `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                        <path className="cls-1"
                            d="M0,.89V34.11A1,1,0,0,0,1,35H34a1,1,0,0,0,1-.89V.89A1,1,0,0,0,34,0H1A1,1,0,0,0,0,.89ZM33,33.22H2V1.78H33Z" />
                        <path className="cls-1"
                            d="M9.67,11.87h5.18V9.52H27.53a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9H14.85V5.88a1,1,0,0,0-1-.89H10.69a1,1,0,0,0-1,.89V7.73H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h2.2Zm2-2.39V6.74h1.1v3.31h-1.1Z" />
                        <path className="cls-1"
                            d="M22.52,21.14h3.14a1,1,0,0,0,1-.9V18.43h.85a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-.85V15.15a1,1,0,0,0-1-.89H22.52a1,1,0,0,0-1,.89v1.49h-14a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h14v1.81A1,1,0,0,0,22.52,21.14Zm1-2.75V16h1.1v3.32h-1.1Z" />
                        <path className="cls-1"
                            d="M14.77,30.05h3.14a1,1,0,0,0,1-.9V27.34h8.6a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-8.6V24.06a1,1,0,0,0-1-.89H14.77a1,1,0,0,0-1,.89v1.49H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h6.28v1.81A1,1,0,0,0,14.77,30.05Zm1-2.75V24.91h1.1v3.32h-1.1Z" />
                    </g>
                </g>
            </svg>
        ) : (
            <svg width="22" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35 35">
                <defs>
                    <style>
                        {`
                    .cls-b {
                        fill: #fff;
                        }
                   `}
                    </style>
                </defs>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_2-2" data-name="Layer 2">
                        <path className="cls-b"
                            d="M0,.89V34.11A1,1,0,0,0,1,35H34a1,1,0,0,0,1-.89V.89A1,1,0,0,0,34,0H1A1,1,0,0,0,0,.89ZM33,33.22H2V1.78H33Z" />
                        <path className="cls-b"
                            d="M9.67,11.87h5.18V9.52H27.53a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9H14.85V5.88a1,1,0,0,0-1-.89H10.69a1,1,0,0,0-1,.89V7.73H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h2.2Zm2-2.39V6.74h1.1v3.31h-1.1Z" />
                        <path className="cls-b"
                            d="M22.52,21.14h3.14a1,1,0,0,0,1-.9V18.43h.85a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-.85V15.15a1,1,0,0,0-1-.89H22.52a1,1,0,0,0-1,.89v1.49h-14a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h14v1.81A1,1,0,0,0,22.52,21.14Zm1-2.75V16h1.1v3.32h-1.1Z" />
                        <path className="cls-b"
                            d="M14.77,30.05h3.14a1,1,0,0,0,1-.9V27.34h8.6a1,1,0,0,0,1-.89h0a1,1,0,0,0-1-.9h-8.6V24.06a1,1,0,0,0-1-.89H14.77a1,1,0,0,0-1,.89v1.49H7.47a1,1,0,0,0-1,.9h0a1,1,0,0,0,1,.89h6.28v1.81A1,1,0,0,0,14.77,30.05Zm1-2.75V24.91h1.1v3.32h-1.1Z" />
                    </g>
                </g>
            </svg>
        )
    )
}

export default TacheControleDossierPhysiqueBanque