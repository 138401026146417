import React from 'react'
import '../css/StatistiquesGlobal.css'
import Label from '../svg/Label'
import MontantPanierEtat from './statistical_components/MontantPanierEtat'
import NbrCommandAge from './statistical_components/NbrCommandAge'
import NbrCommandEtat from './statistical_components/NbrCommandEtat'
import NbrCommandEtatAge from './statistical_components/NbrCommandEtatAge'
import NbrCommandEtatSecteur from './statistical_components/NbrCommandEtatSecteur'
import NbrCommandEtatTypeCompte from './statistical_components/NbrCommandEtatTypeCompte'
import NbrCommandSecteur from './statistical_components/NbrCommandSecteur'
import NbrCommandTypeCompte from './statistical_components/NbrCommandTypeCompte'
import NbrCommandWilaya from './statistical_components/NbrCommandWilaya'
import SalaryEtat from './statistical_components/SalaryEtat'

function StatistiquesGlobal() {
    return (
        <div className='statistiques-global'>
            <h1>
                <Label />
                <span>Statistiques Globales :</span>
            </h1>
           <NbrCommandWilaya />
           <NbrCommandEtat />
           <MontantPanierEtat />
           <SalaryEtat />
           <NbrCommandAge />
           <NbrCommandEtatAge />
           <NbrCommandSecteur />
           <NbrCommandEtatSecteur />
           <NbrCommandTypeCompte />
           <NbrCommandEtatTypeCompte />
        </div>
    )
}

export default StatistiquesGlobal