import React, { useContext, useState } from 'react'
import UserContext from '../context/UserContext';
import axios from 'axios';
import '../css/LoginPage.css';

function LoginPage() {
  const { user, setUser } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  // let home_url = process.env.HOME_URL;


  const submithandler = async (e) => {
    e.preventDefault();
 
    await axios.post('https://diardzair.com.dz/api/taksit/rest/register',JSON.stringify({ username, password }), {
      // await axios.post('/api/taksit/rest/register', { username, password }, {
    // await axios.post(`https://diardzair.com.dz/api/taksit/rest/register`, { username, password }, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
        // 'Content-Type': 'application/json'
      },
      rejectUnauthorized: false,//add when working with https sites
      requestCert: false,//add when working with https sites
      agent: false,//add when working with https sites
    }).then(res => {
      if(res.data.errorCode){
        setError(res.data.errorMessage);
      }else if(res.data.roles.includes('ROLE_CREDIT_ALSALAM_ADMIN') || res.data.roles.includes('ROLE_CREDIT_ALSALAM_OPR')){ 
        setUser(res.data)
        localStorage.setItem('userInfo', JSON.stringify(res.data));
      }else{
        setError("vous n'êtes pas autorisé à vous connecter");
      }
    }).catch(err => {
      console.log(err.message);
      setError('Invalid Username/Password')
    })
  }

  return (
    <div className='login'>
      <h1>Login</h1>
      <form onSubmit={submithandler}>
        <div className="box">
          <input type="text" required
            onChange={e => setUsername(e.target.value)} value={username} />
          <span>Username</span>
        </div>
        <div className="box">
          <input type="password" required
            onChange={e => setPassword(e.target.value)} value={password} />
          <span>Password</span>
        </div>
        {
          error ? (          
            <div className='box'>
              <span className='error-message'>{error}</span>
            </div>
          ) :  ""
        }
        <div className='box text-center'>
          <button type='submit'>
            Login
           
          </button>
        </div>
      </form>
    </div>
  )
}

export default LoginPage