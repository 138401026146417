import './App.css';
import React from 'react'
import HomePage from './pages/HomePage';
import { Routes, Route } from 'react-router-dom';
import DetailPage from './pages/DetailPage';
import UserContext from './context/UserContext';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import { useState, useMemo } from 'react';
import VisibleLayout from './layouts/VisibleLayout';
import { ProtectedLayout } from './layouts/ProtectedLayout';
import PageNotFound from './pages/PageNotFound';
import StatistiquesPage from './pages/StatistiquesPage';
import StockPage from './pages/StockPage';
import StockDetailPage from './pages/StockDetailPage';


function App() {
    const [user, setUser] = useState(localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')): null);
    const [itemActive, setItemActive] = useState(localStorage.getItem('itemActive') ? localStorage.getItem('itemActive') : 'acceuil'); 
    const [filterParams, setFilterParams] = useState(localStorage.getItem('filterParams') ? JSON.parse(localStorage.getItem('filterParams')) : {
        id : '', nom : '', dossierBanque : '', dossierClient : '', etat : '', actionBanque : ''
    }); 
    const value = useMemo(
        () => ({ user, setUser, itemActive, setItemActive, filterParams, setFilterParams }),
        [user,itemActive,filterParams]
    );
    return (
        <>
            <UserContext.Provider value={value}>
                <Routes>
                   
                    <Route path="/banque/" element={<VisibleLayout />}>
                        <Route name='Login' path="login" element={<LoginPage />} />
                        {/* <Route name='Register' path='/banque/register' element={<RegisterPage />} /> */}
                    </Route>

                    <Route path="/banque/" element={<ProtectedLayout />}>
                        <Route name='Home' path="" element={<HomePage />} />
                        <Route name='Home_pagination' path="page/:page" element={<HomePage />} />
                        <Route name='Detail' path="detail/:hash" element={<DetailPage />} />
                        <Route name='Stock' path ='stock/' element={<StockPage />} />
                        <Route name='Stock_pagination' path ='stock/page/:page' element={<StockPage />} />
                        <Route name='Stock-Detail' path ='stock/detail/:hash' element={<StockDetailPage />} />
                        <Route name='Statistique' path="statistiques/" element={<StatistiquesPage />} />
                    </Route>
                    
                    <Route path="*" element={<PageNotFound />} />

                    <Route path="/" element={<ProtectedLayout />}>
                        <Route name='Initial' path="" element={<HomePage />} />
                    </Route>
                    
                </Routes>
            </UserContext.Provider>

        </>
    )
}

export default App;
